import React, {Component} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {Link} from "react-router-dom";
import {closeQuickViewAction} from "../../actions/quickViewActions";
import {addToCartAction, updateCartAction} from "../../actions/cartActions";
import { printDeliveryWeek } from '../../utils/productUtils'
import { withTranslation } from 'react-i18next';
import {
    mergeWithLivePrice,
    calculateLowestPrice,
    printProductDisplayPrice,
    printProductPrice,
    getProductPrices, getLivePrices
} from '../../utils/priceUtils'
import {getCurrencySymbol} from '../../utils/priceUtils'

import MultiGrid from "../MultiGrid/MultiGrid";
import PriceLoader from "../Common/PriceLoader";

import Api from '../../controllers/Api'

import {ReactComponent as CloseRoundSvg} from '../assets/close_round.svg'

import './QuickView.scss'

class QuickView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quickViewProduct: null,
            livePrices: [],
            selectedColor: false
        }
    }

    componentDidMount() {
        const {
            productId,
            product,
            customer: {activeCustomer},
            presentationMode,
            cart
        } = this.props

        if(product) {
            //Get quickview product
            this.setState({
                quickViewProduct: product
            })

            getLivePrices([product], activeCustomer, false).then(livePrices => {
                this.setState({
                    livePrices
                })
            })
        }else {
            //Get quickview product

            //Pass cart presentation mode
            Api.product.get(productId, presentationMode, this.props.isSimoneProduct ? false : cart.presentationMode).then(product => {
                this.setState({
                    quickViewProduct: product
                })

                getLivePrices([product], activeCustomer, false).then(livePrices => {
                    this.setState({
                        livePrices
                    })
                })
            })
        }
    }

    findQuickViewProduct(products, quickViewProductId, variationPrices) {
        for (let i = 0; i < products.length; i++) {
            if (products[i]._id === quickViewProductId) {
                return products[i]
                // return mergeWithLivePrice(products[i], variationPrices)
            }
        }
    }

    printProductPrice(lowestPrice, lowestLivePrice, activeCustomer, livePricesDisabled) {
        let currencySymbol = '€'

        if (activeCustomer && activeCustomer.currency) {
            currencySymbol = getCurrencySymbol(activeCustomer)
        }

        if ((!lowestLivePrice && !lowestPrice) || livePricesDisabled) {
            return <div className='quickview-product-price'>
                <span className="value">{currencySymbol}{lowestPrice}</span>
            </div>
        }

        //If we have prices
        if (lowestLivePrice) {
            if (lowestLivePrice < lowestPrice) {
                return <div className='quickview-product-price'>
                    <span className="old-price value">{currencySymbol}{lowestPrice}</span>
                    <span className="value">{currencySymbol}{lowestLivePrice}</span>
                </div>
            } else {
                return <div className='quickview-product-price'>
                    <span className="value">{currencySymbol}{lowestLivePrice}</span>
                </div>
            }
        } else {
            return <PriceLoader/>
        }
    }

    render() {
        const { t } = this.props;
        const {
            quickView: {quickViewProductId, inputValues},
            closeQuickViewAction,
            addToCartAction,
            updateCartAction,
            customer: {activeCustomer},
            cart: {cartItems},
            presentationMode,
            activeUser,
        } = this.props

        const { quickViewProduct, livePrices } = this.state

        if (!quickViewProduct) {
            return null
        }

        const {
            price,
            regularPrice,
            livePriceMatched,
        } = getProductPrices(quickViewProduct, livePrices)

        let currency = getCurrencySymbol(activeUser)

        if(activeCustomer) {
            currency = getCurrencySymbol(activeCustomer)
        }

        return <div className="quickview-component show">
            <div className="quickview-component-container">
                <div className="quickview-close" onClick={closeQuickViewAction}>
                    <CloseRoundSvg/>
                </div>
                <div className="product-quickview-wrapper">
                    <div className="left-container image-container">
                        <img src={this.state.selectedColor ? quickViewProduct.additionalImagesWithAtt.filter( image => image.colorcode == this.state.selectedColor ).length ? ( 'https://assets.vampfashion.com/SOFTONE_TMP_IMAGES/' + quickViewProduct.additionalImagesWithAtt.filter( image => image.colorcode == this.state.selectedColor )[0].filename ) : quickViewProduct.image : quickViewProduct.image} alt={quickViewProduct.code}/>
                    </div>
                    <div className="right-container product-data-container">
                        <header className="product-header">
                            <div className="left-container">
                                <div className="title">{quickViewProduct.titleEN}</div>
                                <div className="product-link">
                                    <Link to={`/app/product/${quickViewProduct._id}`}
                                          onClick={() => closeQuickViewAction()}>
                                        <div className="sku">
                                            <span className="label">{t('Art')}.: </span>
                                            <span className="value">{quickViewProduct.code}</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            {
                                quickViewProduct.factory === 'Simone' && <div><img className='simone-logo-quick-buy-cart' src="/simoneLogo.svg"/></div>
                            }
                            <div className="right-container">
                                <div className="product-price-container">
                                    <span className="label">{t('Price')}: </span>
                                    {printProductPrice(price, regularPrice, livePriceMatched, currency, quickViewProduct)}
                                </div>
                                {printDeliveryWeek(quickViewProduct)}
                            </div>
                        </header>
                        <div className="multigrid-container">
                            <MultiGrid
                                currency={currency}
                                product={quickViewProduct}
                                selectedColor={this.state.selectedColor}
                                setSelectedColor={(e) => this.setState({selectedColor: e})}
                                livePrices={livePrices}
                                cartItems={cartItems}
                                addToCartAction={(payload) => {

                                    let presentationModeToSend = presentationMode

                                    if(!presentationModeToSend) {
                                        //Check from product
                                        presentationModeToSend = quickViewProduct.markedAsPreorder
                                    }

                                    if (inputValues.length) {
                                        updateCartAction(payload, presentationModeToSend)
                                    } else {
                                        addToCartAction(payload, presentationModeToSend)
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = ({quickView, customer, cart, settings, auth}) => {
    return {
        activeUser: auth.activeUser,
        quickView,
        customer,
        settings: settings.settings,
        cart,
    }
}

export default withTranslation()(connect(mapStateToProps, {closeQuickViewAction, addToCartAction, updateCartAction})(QuickView))
