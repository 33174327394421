import React, {Component} from 'react'
import moment from 'moment'
import DatePicker from "react-datepicker";

import Api from '../../controllers/Api'

import CheckoutFormSection from './CheckoutFormSection'
import CheckoutAddressBox from './CheckoutAddressBox'

import Button from "../Button";
import FullScreenLoader from "../Common/FullScreenLoader";
import Loader from "../Common/Loader";

import { getCustomerPoints, getCustomerPointsOptions } from "../../utils/customerUtils";

import {ReactComponent as MicrophoneSvg} from '../assets/microphone.svg';
import {ReactComponent as LoyaltySvg} from '../assets/loyalty.svg';
import { withTranslation } from 'react-i18next';

import './CheckoutForm.scss'

let recognition = null
if (window.webkitSpeechRecognition) {
    const recognition = new window.webkitSpeechRecognition
    recognition.continous = true
    recognition.interimResults = true
    recognition.lang = 'el-GR'
}

class CheckoutForm extends Component {
    constructor(props) {
        super(props)

        //const { isPreorder } = this.props
        const isPreorder = this.props.cart.presentationMode


        this.state = {
            activeShippingAddress: null,
            sendingOrder: false,
            deliveryDate: isPreorder ? '' : new Date(),
            placeOfOrder: '',
            bonus: false,
            notes: '',
            season: '',
            type: '',
            listening: false,
            otherSalesman: null,
            paymentOption: null,
            paymentOptions: [],
            pointsSelected: 0,
        }

        this.toggleListen = this.toggleListen.bind(this)
        this.handleListen = this.handleListen.bind(this)
    }

    toggleListen() {
        this.setState({
            listening: !this.state.listening
        }, this.handleListen)
    }

    handleListen() {
        if (this.state.listening) recognition.start()

        let finalTranscript = ''
        recognition.onresult = event => {
            let interimTranscript = ''

            for (let i = event.resultIndex; i < event.results.length; i++) {
                const transcript = event.results[i][0].transcript;
                if (event.results[i].isFinal) finalTranscript += transcript + ' ';
                else interimTranscript += transcript;
            }
            this.setState({
                notes: finalTranscript,
                listening: false
            })
        }
    }

    printCustomerShippingAddressesOptions(customer) {
        if (!customer.customer[0].shippingAddresses || !customer.customer[0].shippingAddresses.length) {
            return <option>
                {`${customer.name}, 
                ${customer.customer[0].billingAddress.address}, 
                ${customer.customer[0].billingAddress.zip},
                ${customer.customer[0].billingAddress.city},
                ${customer.customer[0].billingAddress.country}`}
            </option>
        }

        const addressesArray = [];

        addressesArray.push(customer.customer[0].billingAddress)

        for(let i=0; i < customer.customer[0].shippingAddresses.length; i++) {
            addressesArray.push(customer.customer[0].shippingAddresses[i])
        }

        return addressesArray.map((address, key) => {
            return <option value={address._id} key={key}>
                {`${customer.name}, 
                ${address.address}, 
                ${address.zip},
                ${address.city},
                ${address.country}`}
            </option>
        })
    }

    componentDidMount() {
        const {customer, topSeasons, orderType, activeUser, userSalesmen} = this.props

        if(customer) {
            //Set shipping address
            if (!customer.customer[0].shippingAddresses || !customer.customer[0].shippingAddresses.length) {
                this.setState({
                    activeShippingAddress: customer.customer[0].billingAddress
                })
            } else {
                //Default address set flag
                let defaultAddressSet = false

                //Find default shipping
                for (let i = 0; i < customer.customer[0].shippingAddresses.length; i++) {
                    if (customer.customer[0].shippingAddresses[i].defaultAddress) {
                        defaultAddressSet = true
                        this.setState({
                            activeShippingAddress: customer.customer[0].shippingAddresses[i]
                        })

                        break;
                    }
                }

                if (!defaultAddressSet) {
                    this.setState({
                        activeShippingAddress: customer.customer[0].shippingAddresses[0]
                    })
                }
            }

            //Set topSeason and orderTye
            let topSeasonInState = ''
            if (topSeasons.length === 1) {
                topSeasonInState = topSeasons[0].softoneId
            }

            //Check if has to display Assign to other salesman
            let otherSalesmanId = null

            if (activeUser.allAccess && userSalesmen && userSalesmen.length) {
                otherSalesmanId = activeUser.id
            }

            //Get customer payment options
            Api.payment.get(customer._id).then(res => {
                this.setState({
                    paymentOptions: res.map(item => {
                        return {
                            label: item.nameEn || item.nameGr,
                            value: item.softoneId,
                        }
                    } )
                })
            })

            this.setState({
                season: topSeasonInState,
                type: orderType.value,
                otherSalesman: otherSalesmanId,
                paymentOption: customer.customer[0].paymentSoftoneId || ''
            })
        }
    }

    printOverShippingCost() {
        const { t } = this.props;
        const {customer} = this.props

        if(customer.b2bView === 'GR' && customer.customer[0].billingAddress.softoneCountryCode !== 1000) {
            return <div className="over-shipping-notice">
                {t('For orders of total value under 400€ transport costs of 10€ may apply.')}
            </div>
        }

        return null
    }

    changeActiveShippingAddress(addressId) {
        const {customer} = this.props

        let shippingAddressSet = false;

        for (let i = 0; i < customer.customer[0].shippingAddresses.length; i++) {
            if (customer.customer[0].shippingAddresses[i]._id === addressId) {
                shippingAddressSet = true
                this.setState({
                    activeShippingAddress: customer.customer[0].shippingAddresses[i]
                })
                break
            }
        }

        if(!shippingAddressSet) {
            this.setState({
                activeShippingAddress: customer.customer[0].billingAddress
            })
        }
    }

    printSeasonOptions(topSeasons) {
        let seasonsArrayOptions = [];

        if(topSeasons.length > 1) {
            seasonsArrayOptions.push(<option key={1} value="">Please select a season</option>)
        }

        for(let i=0; i < topSeasons.length; i++) {
            seasonsArrayOptions.push(<option key={topSeasons[i].softoneId} value={topSeasons[i].softoneId}>{topSeasons[i].name}</option>)
        }

        return seasonsArrayOptions
    }

    printOrderTypeOptions(orderType) {
        if (orderType) {
            return <option value={orderType.value}>{orderType.label}</option>
        }
    }

    handleDeliveryDateChange(e) {
        this.setState({
            deliveryDate: e.target.value
        })
    }

    printDeliveryDateInput() {

        let { season, deliveryDate } = this.state

        if(!season) {
            return 'Please select a season'
        }

        const { topSeasons } = this.props
        const isPreorder = this.props.cart.presentationMode

        //After order case
        if(!isPreorder) {
            return <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={this.state.deliveryDate || new Date()}
                onChange={date => this.setState({
                    deliveryDate: date
                })}
            />
        }

        //Presentation mode
        for(let i = 0; i < topSeasons.length; i++) {
            if(topSeasons[i].softoneId == season) {
                season = topSeasons[i]
                break
            }
        }

        let now = new moment().utc();
        let finalDate = new moment(season.finalDate).utc();

        //Check dates, if today is les than final date
        if ((now.isBefore(finalDate)) && season.deliveryDates && season.deliveryDates.length) {
            return <select onChange={(e) => this.handleDeliveryDateChange(e)} value={deliveryDate}
                           name="deliveryDate">
                <option value="">Please select an option</option>
                {season.deliveryDates.map((deliveryDateItem, key) => {
                    let dateItem = new moment(deliveryDateItem.date).utc();

                    return <option value={deliveryDateItem.date}
                                   key={key}>{dateItem.format('DD-MM-YYYY')}</option>
                })}
            </select>
        } else {
            //Get dates with remarks
            const datesWithRemarks = season.deliveryDates
                .filter(item => item.remarks === '1')

            return <select onChange={(e) => this.handleDeliveryDateChange(e)} value={deliveryDate}
                           name="deliveryDate">
                <option value="">Please select an option</option>
                {datesWithRemarks.map((deliveryDateItem, key) => {
                    let dateItem = new moment(deliveryDateItem.date).utc();

                    return <option value={deliveryDateItem.date}
                                   key={key}>{dateItem.format('DD-MM-YYYY')}</option>
                })}
            </select>
        }
    }

    handlePlaceOfOrderChange(e) {
        this.setState({
            placeOfOrder: e.target.value
        })
    }

    handlePartialShipmentChange(e) {
        this.setState({
            partialShipment: e.target.value
        })
    }

    handleBonusChange(e) {
        this.setState({
            bonus: e.target.checked
        })
    }

    sendOrder() {
        const {
            activeShippingAddress,
            season,
            type,
            deliveryDate,
            bonus,
            placeOfOrder,
            partialShipment,
            notes,
            otherSalesman,
            paymentOption,
            pointsSelected,
        } = this.state

        const {
            checkoutAction,
            activeUser
        } = this.props
        const isPreorder = this.props.cart.presentationMode

        let isLoggedInSalesman = false;

        if (activeUser.role === 'admin' || activeUser.role === 'salesman') {
            isLoggedInSalesman = true
        }

        //Check if all data are selected
        if (!season) {
            alert('Please select a season')
            return false
        }

        if(!deliveryDate) {
            alert('Please select delivery date')
            return false;
        }

        if(isPreorder && isLoggedInSalesman && !placeOfOrder) {
            alert('Please select place of order')
            return false;
        }

        // if(!partialShipment) {
        //     alert('Please select if you would like partial shipment')
        //     return false;
        // }

        let dateToSend = deliveryDate

        if(!isPreorder) {
            dateToSend = new moment(deliveryDate).format('YYYY-MM-DD').toString()
        }

        checkoutAction(
            activeShippingAddress._id,
            {
                season,
                type,
                deliveryDate: dateToSend,
                bonus,
                placeOfOrder,
                partialShipment,
                notes,
                otherSalesman,
                paymentOption,
                pointsSelected,
                isPreorder,
            }
        )
        this.setState({
            sendingOrder: true
        })
    }

    printCustomerPoints(onlyTotal = false) {
        const { t } = this.props;
        const {customer, cartTotalPriceFlat, activeUser} = this.props

        if(activeUser.role != 'customer') {
            return null;
        }


        const points = getCustomerPoints(customer)

        //Also check for preorder!!!!!
        if(!points) {
            return null;
        }

        if(onlyTotal) {
            return points
        }

        const pointsOptions = getCustomerPointsOptions(cartTotalPriceFlat, points)

        if(!pointsOptions || !pointsOptions.length) {
            return null
        }

        return <div id="customer-points-section" className="form-group customer-points">
            <div className="customer-points-intro">
                <p>You have <strong>{points}</strong> available points</p>
                <LoyaltySvg/>
            </div>
            <select onChange={(e) => {
                this.setState({
                    pointsSelected: parseInt(e.target.value)
                })
            } }>
                <option key={0} value={0}>{t('Select points to redeem')}</option>
                {pointsOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
        </div>
    }

    printAssignToOtherCustomer() {
        const { t } = this.props;
        const {otherSalesman} = this.state
        const {activeUser, userSalesmen} = this.props

        if (!activeUser.allAccess || !userSalesmen || !userSalesmen.length) {
            return []
        }

        return <div className="form-group">
            <span className="label">{t('Assign order to other salesman')}</span>
            <span className="value">
                <select name="otherSalesman" id="otherSalesman" value={otherSalesman}
                        onChange={(e) => this.setState({otherSalesman: e.target.value})}>
                    {userSalesmen.map(({name, id}) => {
                        return <option key={id} value={id}>
                            {name}
                        </option>
                    })}
                </select>
            </span>
        </div>
    }

    printPaymentOptions() {
        const { paymentOptions } = this.state

        if(!paymentOptions || !paymentOptions.length) {
            return null
        }

        return paymentOptions.map(({value, label}) => <option key={value} value={value}>{label}</option>)
    }

    printPointsTopNotice() {
        const { t } = this.props;
        const points = this.printCustomerPoints(true)

        if(!points || parseInt(points) < 10000) {
            return null
        }

        return <div className="points-top-notice">
            <h3>You have collected <strong>{points}</strong> points</h3>
            <h4>Redeem them at <strong><a href="#customer-points-section">{t('Order Fields')}</a></strong></h4>
        </div>

    }

    render() {
        const {
            activeUser,
            customer,
            cartTotalQty,
            cartTotalPrice,
            checkoutAction,
            topSeasons,
            orderType,
            loadedOrder,
        } = this.props

        const {
            activeShippingAddress,
            sendingOrder,
            bonus,
            placeOfOrder,
            partialShipment,
            notes,
            season,
            type,
            listening,
            paymentOption,
            pointsSelected,
        } = this.state
        const { t } = this.props;

        if (!activeShippingAddress) {
            return <div className="checkout-error">
                <p>
                    {t('There is not any selected shipping address.')}
                </p>
                <p>
                    {t('Please provide on from the customer page to continue')}
                </p>
            </div>
        }

        if (sendingOrder) {
            return <FullScreenLoader message={t('Please wait while we are processing your order...')}/>
        }

        let isLoggedInSalesman = false;

        if (activeUser.role === 'admin' || activeUser.role === 'salesman') {
            isLoggedInSalesman = true
        }


        return <div className="checkout-container">
            {this.printPointsTopNotice()}
            <div className="checkout-form">
                {/*Personal Information*/}
                <CheckoutFormSection
                    sectionClass='personal-info'
                    sectionNumber={1}
                    sectionTitle='Personal Information'
                >
                    <div className="form-group">
                        <span className="label">{t('Company Name')}</span>
                        <span className="value">{customer.name}</span>
                    </div>
                    <div className="form-group">
                        <span className="label">{t('Email')}</span>
                        <span className="value">{customer.email}</span>
                    </div>
                    <div className="form-group">
                        <span className="label">{t('Tax Vat')}</span>
                        <span className="value">{customer.customer[0].afm}</span>
                    </div>
                    <div className="form-group">
                        <span className="label">{t('Alt Code')}</span>
                        <span className="value">{customer.customer[0].altCode}</span>
                    </div>
                </CheckoutFormSection>
                {/*Billing Address*/}
                <CheckoutFormSection
                    sectionClass='billing-address'
                    sectionNumber={2}
                    sectionTitle={t('Billing Address')}
                >
                    <CheckoutAddressBox
                        name={customer.name}
                        address={customer.customer[0].billingAddress}
                    />
                </CheckoutFormSection>
                {/*Shipping Address*/}
                <CheckoutFormSection
                    sectionClass='shipping-address'
                    sectionNumber={3}
                    sectionTitle={t('Shipping Address')}
                >
                    <CheckoutAddressBox
                        name={customer.name}
                        address={activeShippingAddress}
                    />
                    <select value={activeShippingAddress._id} onChange={(e) => {
                        this.changeActiveShippingAddress(e.target.value)
                    }}>
                        {this.printCustomerShippingAddressesOptions(customer)}
                    </select>
                </CheckoutFormSection>
                {/*Extra Checkout Data*/}
                <CheckoutFormSection
                    sectionClass='extra-order-data'
                    sectionNumber={4}
                    sectionTitle='Order Fields'
                >
                    <div className="form-group">
                        <span className="label">{t('Season')}</span>
                        <span className="value">
                            <select name="season" id="season" value={season}
                                    onChange={(e) => this.setState({season: e.target.value})}>
                                {this.printSeasonOptions(topSeasons)}
                            </select>
                        </span>
                    </div>
                    {/*<div className="form-group">*/}
                    {/*    <span className="label">Type</span>*/}
                    {/*    <span className="value">*/}
                    {/*        <select name="type" id="type" value={type}*/}
                    {/*                onChange={(e) => this.setState({type: e.target.value})}>*/}
                    {/*            {this.printOrderTypeOptions(orderType)}*/}
                    {/*        </select>*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                    {this.props.cart.presentationMode ?
                        <div className={`form-group ${(isLoggedInSalesman && !this.state.placeOfOrder) ? 'error' : ''}`}>
                            <span className={`label ${isLoggedInSalesman ? 'required' : ''}`}>{t('Place of order')}</span>
                            <span className="value">
                            <select value={placeOfOrder} name="placeOfOrder" id="placeOfOrder"
                                    onChange={(e) => this.handlePlaceOfOrderChange(e)}>
                                <option value="">-</option>
                                <option value="1">{t('Fair')}</option>
                                <option value="2">{t('Showroom')}</option>
                                <option value="3">{t('Shop')}</option>
                            </select>
                        </span>
                        </div>
                        :
                        null
                    }
                    {/*<div className="form-group">*/}
                    {/*    <span className="label">Partial Shipment</span>*/}
                    {/*    <span className="value">*/}
                    {/*        <select value={partialShipment} name="partialShipment" id="partialShipment"*/}
                    {/*                onChange={(e) => this.handlePartialShipmentChange(e)}>*/}
                    {/*            <option value="">-</option>*/}
                    {/*            <option value="yes">Yes</option>*/}
                    {/*            <option value="no">No</option>*/}
                    {/*        </select>*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                    <div className={`form-group ${!this.state.deliveryDate ? 'error' : ''} `}>
                        <span className={`label required`}>Delivery Date</span>
                        <span className="value">
                            {this.printDeliveryDateInput(topSeasons)}
                        </span>
                    </div>
                    {(isLoggedInSalesman) ?
                        <div className="form-group hidden">
                            <span className="label">{t('Payment Method')}</span>
                            <span className="value">
                            <select value={paymentOption} name="paymentMethod" id="paymentMethod"
                                    onChange={(e) => {
                                        this.setState({
                                            paymentOption: e.target.value
                                        })
                                    }}>
                                <option key={1} value="">-</option>
                                {this.printPaymentOptions()}
                            </select>
                        </span>
                        </div>
                    :
                    null
                    }
                    {this.printAssignToOtherCustomer()}
                    {this.printCustomerPoints()}
                    {/*<div className="form-group">*/}
                    {/*    <span className="label">Bonus</span>*/}
                    {/*    <span className="value">*/}
                    {/*        <input value={bonus} type="checkbox" onChange={(e) => this.handleBonusChange(e)}/>*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                    <div className="form-group textarea-group">
                        <span className="label">{t('Notes')}</span>
                        <span className="value">
                            <textarea value={notes} onChange={(e) => {
                                this.setState({
                                    notes: e.target.value
                                })
                            }} name="notes" id="notes" cols="30" rows="10"></textarea>
                            {recognition ?
                                <Button callback={this.toggleListen}>
                                    {!listening ? <MicrophoneSvg/> : <Loader/>}
                                </Button>
                                : null}
                        </span>
                    </div>
                </CheckoutFormSection>
                {/*Order Review*/}
                <CheckoutFormSection
                    sectionClass='order-review'
                    sectionNumber={5}
                    sectionTitle='Order Review'
                >
                    <div className="form-group">
                        <span className="label">{t('Total Price')}</span>
                        <span className="value">{cartTotalPrice}</span>
                    </div>
                    <div className="form-group">
                        <span className="label">{t('Total Quantity')}</span>
                        <span className="value">{cartTotalQty}</span>
                    </div>
                </CheckoutFormSection>
                {this.printOverShippingCost()}
            </div>
            <div className="checkout-submit">
                <Button title={`${loadedOrder.editMode ? 'Edit Order' : 'Place Order'}`} callback={() => {
                    this.sendOrder()
                }}/>
            </div>
        </div>
    }
}

export default withTranslation()(CheckoutForm)
